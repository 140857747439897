import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const MUISelect = ({
  children,
  names,
  state,
  setState,
  required = true,
  custom = false,
  disabled = false,
}) => {
  const { label, key } = names;

  const onValChange = (e) => {
    let value = e.target.value;
    if (custom !== false) {
      custom(value);
    } else {
      setState({ ...state, [key]: value });
    }
  };

  return (
    <FormControl
      variant="standard"
      sx={{ minWidth: 120, margin: 0 }}
      className="w-full !mb-3.5 shadow-custom shadow-grey h-fit"
    >
      <InputLabel
        id="demo-simple-select-standard-label"
        className="!left-2.5 !-top-1"
      >
        {label} {required && <span className="text-red">*</span>}
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        className="!pl-2.5 !mt-4 !pb-1 h-[39px]"
        label={label}
        required={required}
        value={state[key]}
        onChange={onValChange}
        disabled={disabled}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default MUISelect;
