import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <main className="main">
      <section className="w-80 text-center">
        <h3 className="text-blue text-lg">404 Error</h3>
        <h1 className="text-[26px]">Page Not Found</h1>
        <p className="m-4">
          Sorry, the page you are looking for could not be found or has been
          removed
        </p>
        <section className="flex justify-center gap-3">
          <button className="btn" onClick={() => navigate(-1)}>
            <ArrowBackIosIcon className="!-mr-1.5 !text-[15px]" />
            Go Back
          </button>
          <button className="btn btn-blue" onClick={() => navigate("/")}>
            Dashboard
            <DashboardIcon className="!text-xl" />
          </button>
        </section>
      </section>
    </main>
  );
};

export default PageNotFound;
