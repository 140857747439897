import React, { useEffect, useRef, useState } from "react";
import {
  CaptureImage,
  MUIProgress,
  MUISelect,
  SearchBar,
} from "../../components";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { MenuItem } from "@mui/material";
import {
  isNotNullOrEmpty,
  decryptData,
  post,
  checkMembershipNoLength,
  getPakistanDataTime,
  launchToast,
} from "../../functions";
import { toast } from "react-toastify";

const memberObj = {
  id: 0,
  name: "",
  fatherHusbandName: "",
  cnic: "",
  jcic: "",
  jid: "",
  surname: "",
  age: "",
  remarks: "",
};
const pollingObj = {
  station: "",
  booth: "",
  date: "",
};
const searchObj = {
  type: "",
  number: "",
};

const videoConstraintsObj = {
  width: 1080,
  height: 1350,
  facingMode: "user",
  deviceId: "",
};

const VoterRegistration = () => {
  const [videoConstraints, setVideoConstraints] = useState(videoConstraintsObj);
  const [search, setSearch] = useState(searchObj);
  const [member, setMember] = useState(memberObj);
  const [polling, setPolling] = useState(pollingObj);
  const [error, setError] = useState(false);
  const [image, setImage] = useState("");
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const searchInput = useRef(null);

  useEffect(() => {
    const { user } = decryptData();
    setUser(user);
    setLoading(false);
  }, []);

  const resetStates = () => {
    setMember(memberObj);
    setPolling(pollingObj);
    setImage("");
    setError(false);
  };

  const setMemberState = (obj) => {
    setMember({
      id: obj.id,
      name: obj.Name,
      fatherHusbandName: obj.Father_Husband_Name,
      cnic: obj.NIC,
      jcic: obj.JCIC,
      jid: obj.JID,
      age: obj.Age,
      surname: obj.Surname,
      remarks: obj.Remarks,
    });
  };

  const searchMember = async (e) => {
    e.preventDefault();
    toast.dismiss();

    let valid = checkMembershipNoLength(search.type, search.number);
    setError(valid);

    if (!valid) {
      const response = await post("/member/search", {
        doctype: search.type,
        docno: search.number,
      });
      if (response?.status === "Success") {
        const { data } = response;
        resetStates();
        setMemberState(data);
      } else if (
        response?.status === "Error" &&
        response?.message === "Member not found."
      ) {
        resetStates();
      } else if (
        response?.status === "Error" &&
        response?.message === "Member is already a registered voter."
      ) {
        const { member, registration } = response?.data;
        resetStates();
        setPolling({
          station: registration.polling_station_name,
          booth: registration.polling_booth_name,
          date: registration.created_at,
        });
        setImage("data:image/jpeg;base64," + registration.image_file);
        setMemberState(member);
      }
    }
  };

  useEffect(() => {
    if (search.type !== "") {
      resetStates();
      toast.dismiss();
      if (search.type === "JCIC") {
        setSearch({ ...search, number: "92211000" });
      } else {
        setSearch({ ...search, number: "" });
      }
      setTimeout(() => {
        searchInput.current.focus();
      }, 100);
    }
  }, [search.type]);

  const convertMemberData = async () => {
    const fd = new FormData();
    await fetch(image)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], member.cnic + ".jpg");
        fd.append("voter_image", file);
        fd.append("polling_booth_id", user.polling_booth_id);
        fd.append("member_id", member.id);
        fd.append("user_id", user.id);
      });
    return fd;
  };

  const registerVoter = async () => {
    setLoading(true);

    const response = await post("/election/voter", await convertMemberData());
    if (response?.status === "Success") {
      setSearch(searchObj);
      setMember(memberObj);
      setPolling(pollingObj);
      setImage("");
      launchToast("success", response.message);
    }
    setLoading(false);
  };

  const checkStationAndBooth = () => {
    return isNotNullOrEmpty(polling.station) && isNotNullOrEmpty(polling.booth);
  };

  if (loading) {
    return <MUIProgress />;
  }

  return (
    <main className="main flex-col">
      <h2 className="heading mt-5">Voter Registration</h2>
      <section className="section !mt-0 !w-[850px] flex gap-12">
        <div className="w-[45%] flex flex-col justify-between">
          <form onSubmit={(e) => searchMember(e)}>
            <h4 className="mb-0">Station: {user?.polling_station_name}</h4>
            <h4>Booth: {user?.polling_booth_name}</h4>
            <MUISelect
              names={{ label: "Select Type", key: "type" }}
              state={search}
              setState={setSearch}
            >
              <MenuItem value="NIC">CNIC</MenuItem>
              <MenuItem value="JCIC">JCIC</MenuItem>
              <MenuItem value="JID">JID</MenuItem>
            </MUISelect>
            {search.type !== "" && (
              <SearchBar
                type={search.type}
                names={{
                  label: `Search using ${
                    search.type === "NIC" ? "C" : ""
                  }${search.type.toUpperCase()}#`,
                  key: "number",
                }}
                ref={searchInput}
                state={search}
                setState={setSearch}
                error={error}
              />
            )}
            {member.id !== 0 && (
              <section
                className={`${
                  checkStationAndBooth() ? "*:text-red" : "*:text-black"
                }`}
              >
                <h4 className="mb-0">
                  Full Name: {member.name} {member.fatherHusbandName}
                </h4>
                <h4 className="mb-0">CNIC#: {member.cnic}</h4>
                <h4 className="mb-0">JCIC#: {member.jcic}</h4>
                {isNotNullOrEmpty(member.jid) && (
                  <h4 className="mb-0">JID#: {member.jid}</h4>
                )}
                {isNotNullOrEmpty(member.surname) && (
                  <h4 className="mb-0">Surname: {member.surname}</h4>
                )}
                <h4 className="mb-0">Age: {member.age}</h4>
                {isNotNullOrEmpty(member.remarks) && (
                  <h4 className="mb-0">Remarks: {member.remarks}</h4>
                )}
                {checkStationAndBooth() && (
                  <React.Fragment>
                    <h4 className="mb-0">Polling Station: {polling.station}</h4>
                    <h4 className="mb-0">Polling Booth: {polling.booth}</h4>
                    <h4 className="mb-0">
                      Date Time: {getPakistanDataTime(polling.date)}
                    </h4>
                  </React.Fragment>
                )}
              </section>
            )}
          </form>
          <button
            className="btn btn-blue mt-7 w-full"
            disabled={image === "" || checkStationAndBooth()}
            onClick={registerVoter}
          >
            <HowToRegIcon />
            Register
          </button>
        </div>
        <CaptureImage
          state={image}
          setState={setImage}
          disabled={member.id === 0 || checkStationAndBooth()}
          constraints={[videoConstraints, setVideoConstraints]}
        />
      </section>
    </main>
  );
};

export default VoterRegistration;
