import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import {
  PageNotFound,
  Dashboard,
  ChangePassword,
  VotingResultEntry,
  VoterRegistration,
  MemberEntry,
  MemberUpload,
  Login,
  ElectionStatus,
  PollingStations,
  Users,
  Candidates,
  VoterCount,
} from "../screens";
import { MUIAppBar, MUIProgress } from "../components";
import { decryptData, get, getCopyrightYear } from "../functions";
import packageJson from "../../package.json";

const AppRoutes = () => {
  const [auth, setAuth] = useState({ condition: false, lastUpdatedOn: "" });
  const [loading, setLoading] = useState(true);
  const [menu, setMenu] = useState([]);
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const getAppBarMenu = async (token) => {
    const response = await get("/auth/menus", token);
    if (response?.status === "Success") {
      setMenu(response.data);
    }
    setLoading(false);
  };

  const authenticateUser = () => {
    const decryptedData = decryptData(false);
    if (decryptedData?.status === "Success") {
      setAuth({ condition: true, lastUpdatedOn: "AppRoutes" });
      setUsername(decryptedData.user.name);
      getAppBarMenu(decryptedData.token);
    } else {
      navigate("/login");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.lastUpdatedOn !== "AppRoutes") {
      authenticateUser();
    }
  }, [auth]);

  const routes = [
    {
      element: <MemberEntry />,
      path: "/master/member/entry",
    },
    {
      element: <MemberUpload />,
      path: "/master/member/upload",
    },
    {
      element: <PollingStations />,
      path: "/master/polling-stations",
    },
    {
      element: <Candidates />,
      path: "/master/candidates",
    },
    {
      element: <VotingResultEntry />,
      path: "/election/result/entry",
    },
    {
      element: <ElectionStatus />,
      path: "/election/status",
    },
    {
      element: <VoterCount />,
      path: "/election/voter-count",
    },
    {
      element: <Users />,
      path: "/admin/users",
    },
    {
      element: <VoterRegistration />,
      path: "/voter/registration",
    },
  ];

  if (loading) {
    return <MUIProgress />;
  }

  return (
    <React.Fragment>
      {location.pathname !== "/login" &&
        auth.condition &&
        menu[0] !== undefined && (
          <MUIAppBar menu={menu} setAuth={setAuth} username={username} />
        )}
      <Routes>
        <Route
          path="/login"
          element={<Login auth={auth} setAuth={setAuth} />}
        />
        {auth.condition && (
          <React.Fragment>
            <Route path="/" element={<Dashboard />} />
            <Route path="/change-password" element={<ChangePassword />} />
          </React.Fragment>
        )}
        {menu.map(({ options }) =>
          options.map(({ route }) =>
            routes.map(
              ({ path, element }) =>
                path === route && <Route path={path} element={element} />
            )
          )
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {location.pathname !== "/login" &&
        auth.condition &&
        menu[0] !== undefined && (
          <footer className="bg-blue h-7 w-full text-white py-1 px-10 text-sm flex justify-between">
            <span>
              Copyright © {getCopyrightYear()} KPSIAJ | All Rights Reserved
            </span>
            <span>Version: {packageJson.version}</span>
          </footer>
        )}
    </React.Fragment>
  );
};

export default AppRoutes;
