import React, { useEffect, useState } from "react";
import { MUIProgress } from "../../components";
import { get, isNotNullOrEmpty, post, launchToast } from "../../functions";

const ElectionStatus = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({});

  const getElectoralStatus = async () => {
    const response = await get("/config/election/status");
    if (response?.status === "Success") {
      setStatus(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getElectoralStatus();
  }, []);

  const updateElectoralStatus = async () => {
    setLoading(true);
    const response = await post("/config/election/status", {
      election_status: status.next_status,
    });
    if (response?.status === "Success") {
      setStatus(response.data);
      launchToast("success", response.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <MUIProgress />;
  }

  return (
    <main className="main">
      <section className="section">
        <h2 className="heading">Election Status</h2>
        <h4 className="text-center">Current Status: {status?.status_text}</h4>
        {isNotNullOrEmpty(status?.action) && (
          <button
            className="btn btn-blue w-full"
            onClick={updateElectoralStatus}
          >
            {status.action}
          </button>
        )}
      </section>
    </main>
  );
};

export default ElectionStatus;
