import React, { useState } from "react";
import Webcam from "react-webcam";
import CameraIcon from "@mui/icons-material/Camera";
import MUISelect from "../mui-components/MUISelect";
import { MenuItem } from "@mui/material";
import { isNotNullOrEmpty } from "../../functions";

const CaptureImage = ({ state, setState, disabled, constraints }) => {
  const [videoConstraints, setVideoConstraints] = constraints;
  const [webcamIssues, setWebcamIssues] = useState({
    permission: true,
    camera: true,
    videoSource: true,
  });
  const [devices, setDevices] = React.useState([]);
  const webcamRef = React.useRef(null);

  const handleDevices = React.useCallback(
    (mediaDevices) => {
      let videoDevices = mediaDevices.filter(
        ({ kind }) => kind === "videoinput"
      );
      if (!isNotNullOrEmpty(videoConstraints.deviceId)) {
        setVideoConstraints({
          ...videoConstraints,
          deviceId: videoDevices[0].deviceId,
        });
      }
      setDevices(videoDevices);
    },
    [setDevices]
  );

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setState(imageSrc);
  }, [webcamRef]);

  const onUserMediaError = ({ message }) => {
    if (message === "Permission denied") {
      setWebcamIssues({ ...webcamIssues, permission: false });
    } else if (message === "Requested device not found") {
      setWebcamIssues({ ...webcamIssues, camera: false });
    } else if (message === "Could not start video source") {
      setWebcamIssues({ ...webcamIssues, videoSource: false });
    }
  };

  const onUserMedia = () => {
    if (!isNotNullOrEmpty(devices[0])) {
      navigator.mediaDevices?.enumerateDevices().then(handleDevices);
    }
  };

  if (
    !webcamIssues.camera ||
    !webcamIssues.permission ||
    !webcamIssues.videoSource
  ) {
    const { camera, permission, videoSource } = webcamIssues;
    return (
      <div className="h-auto w-[59%] flex justify-center items-center border-[1px] border-grey rounded-lg">
        {!camera && <h3>Camera Not Found</h3>}
        {!permission && <h3>Access Denied</h3>}
        {!videoSource && <h3>Could not start video source</h3>}
      </div>
    );
  }

  return (
    <section className="w-[59%] flex flex-col justify-between gap-6">
      <div className="bg-blue shadow-custom shadow-darkgrey rounded-lg h-80">
        {state === "" ? (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMediaError={onUserMediaError}
            onUserMedia={onUserMedia}
            className="rounded-lg"
          />
        ) : (
          <img src={state} className="rounded-lg" alt="captured-image" />
        )}
      </div>
      <div className="mt-2">
        <MUISelect
          names={{ label: "Select Camera", key: "deviceId" }}
          state={videoConstraints}
          setState={setVideoConstraints}
        >
          {devices.map((device, ind) => (
            <MenuItem key={ind} value={device.deviceId}>
              {device.label || `Device ${ind + 1}`}
            </MenuItem>
          ))}
        </MUISelect>
      </div>
      <span className="text-center">
        {state !== ""
          ? "Image Capture Successful"
          : "Ready to capture your image!"}
      </span>
      <button
        className="btn btn-blue w-full"
        onClick={state === "" ? capture : () => setState("")}
        disabled={disabled}
      >
        <CameraIcon className="!text-xl" />
        {state === "" ? "Capture Image" : "Retake Image"}
      </button>
    </section>
  );
};

export default CaptureImage;
