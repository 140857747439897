import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { logo } from "../../../assets";
import MUIMenu from "../MUIMenu";

const DesktopBar = ({ menu }) => {
  const navigate = useNavigate();

  return (
    <>
      {/* logo */}
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "none", md: "flex" },
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        <img src={logo} className="w-28 my-1" alt="logo" />
      </Box>

      {/* menu buttons */}
      <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
        {menu.map((ele, ind) => (
          <MUIMenu key={ind} moduleName={ele.name} module={ele.options} />
        ))}
      </Box>
    </>
  );
};

export default DesktopBar;
