import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { MUIProgress, Uploader } from "../../../components";
import { post, launchToast } from "../../../functions";

const MemberUpload = () => {
  const [file, setFile] = useState({});
  const [loading, setLoading] = useState(false);

  const uploadMembers = async (e) => {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData();
    formData.append("members_file", file);

    const response = await post("/member/upload", formData);
    if (response?.status === "Success") {
      setFile({});
      launchToast("success", response.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <MUIProgress />;
  }

  return (
    <main className="main">
      <section className="section">
        <h2 className="heading">Upload Members</h2>
        <form onSubmit={uploadMembers}>
          <Uploader state={file} setState={setFile} />
          {file.name !== undefined && (
            <button className="btn btn-blue mt-6 w-full">
              <CloudUploadIcon className="!text-xl" />
              Upload
            </button>
          )}
        </form>
      </section>
    </main>
  );
};

export default MemberUpload;
