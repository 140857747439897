import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { get } from "../../../functions";

const AvatarMenu = ({ username, setAuth }) => {
  const [avatarMenu, setAvatarMenu] = React.useState(null);
  const settings = ["Change Password", "Logout"];
  const navigate = useNavigate();

  const handleCloseAvatarMenu = async (e) => {
    if (e === "Logout") {
      const response = await get("/auth/logout");
      if (response?.status === "Success") {
        localStorage.clear();
        navigate("/login");
        setAuth({ condition: false, lastUpdatedOn: "AppBar" });
      }
    } else {
      navigate("/change-password");
    }
    setAvatarMenu(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <button
          className="flex items-center border-2 bg-white rounded-full"
          onClick={(e) => setAvatarMenu(e.currentTarget)}
        >
          <Avatar className="!bg-darkblue border-2">
            {username[0].toUpperCase()}
          </Avatar>
          <span className="max-md:hidden text-darkblue font-semibold ml-1.5 mr-3">
            {username.toUpperCase()}
          </span>
        </button>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={avatarMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setAvatarMenu(null)}
        open={Boolean(avatarMenu)}
      >
        {settings.map((setting) => (
          <MenuItem
            key={setting}
            onClick={() => handleCloseAvatarMenu(setting)}
          >
            <Typography textAlign="center">{setting}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default AvatarMenu;
