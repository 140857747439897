import React, { useEffect, useState } from "react";
import {
  DataTable,
  MUIProgress,
  MUISelect,
  MUITextField,
} from "../../components";
import { MenuItem, TableCell, TableRow } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {
  isNotNullOrEmpty,
  decryptData,
  get,
  post,
  getPollingStations,
  launchToast,
} from "../../functions";

const dropdownObj = {
  positionId: "",
  stationId: "",
  boothId: "",
};

const VotingResultEntry = () => {
  const [dropdown, setDropdown] = useState(dropdownObj);
  const [pollingStations, setPollingStations] = useState([]);
  const [pollingBooths, setPollingBooths] = useState([]);
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState({ display: false, message: "" });
  const [voteCount, setVoteCount] = useState([]);
  const [user, setUser] = useState({});
  const [electionStatus, setElectionStatus] = useState(true);
  const [totalVoters, setTotalVoters] = useState(0);
  let total = 0;

  const getPositions = async (token) => {
    const response = await get("/lookup/position", token);
    if (response?.status === "Success") {
      setPositions(response.data);
    }
  };

  const getElectoralStatus = async () => {
    const response = await get("/config/election/status");
    if (response?.status === "Success") {
      if (response.data.status === "N") {
        setElectionStatus({ show: true, message: "Election Not Started" });
      } else if (response.data.status === "S") {
        setElectionStatus({ show: true, message: "Election In Progress" });
      }
    }
  };

  const getLookups = async () => {
    const { user, token } = decryptData();
    await getPollingStations(setPollingStations, token);
    await getPositions(token);
    await getElectoralStatus(token);
    setUser(user);
    setLoading(false);
  };

  useEffect(() => {
    getLookups();
  }, []);

  useEffect(() => {
    if (isNotNullOrEmpty(dropdown.stationId)) {
      pollingStations.map(
        (ele) =>
          ele.id === dropdown.stationId && setPollingBooths(ele.polling_booth)
      );
    }
  }, [dropdown.stationId]);

  const getCandidates = async () => {
    const { positionId, boothId } = dropdown;
    const response = await get(`/election/result/${positionId}/${boothId}`);
    if (response?.status === "Success") {
      setVoteCount(response.data.result);
      setTotalVoters(response.data.voter_count);
    }
  };

  useEffect(() => {
    if (
      isNotNullOrEmpty(dropdown.positionId) &&
      isNotNullOrEmpty(dropdown.boothId) &&
      isNotNullOrEmpty(dropdown.stationId)
    ) {
      getCandidates();
    }
  }, [dropdown]);

  const onChangeVal = (val, ind) => {
    let value = +val;
    if (val === "") {
      value = val;
    }
    voteCount[ind].vote_count = value;
    setVoteCount([...voteCount]);
  };

  const getTotalVoteCount = () => {
    let totalVoteCount = 0;
    voteCount.map((ele) => (totalVoteCount += ele.vote_count));
    return totalVoteCount;
  };

  const uploadVoteCount = async (e) => {
    e.preventDefault();
    if (totalVoters !== getTotalVoteCount()) {
      return launchToast(
        "warn",
        "Total Vote Count must be equal to total voters"
      );
    }

    setLoading(true);
    const { stationId, boothId, positionId } = dropdown;
    const response = await post("/election/result", {
      polling_station_id: stationId,
      polling_booth_id: boothId,
      position_id: positionId,
      user_id: user.id,
      vote_result: voteCount,
    });
    if (response.status === "Success") {
      launchToast("success", response.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <MUIProgress></MUIProgress>;
  }

  if (electionStatus.show) {
    return (
      <main className="main">
        <section className="section">
          <h2 className="heading !mb-0">{electionStatus.message}</h2>
        </section>
      </main>
    );
  }

  return (
    <main className="main">
      <section className="section !w-full">
        <h2 className="heading">Voting Result Entry</h2>
        <section className="grid grid-cols-3 gap-5">
          <MUISelect
            names={{ label: "Select Station", key: "stationId" }}
            state={dropdown}
            setState={setDropdown}
          >
            {pollingStations.map(({ id, name }, ind) => (
              <MenuItem key={ind} value={id} className="capitalize">
                {name}
              </MenuItem>
            ))}
          </MUISelect>
          <MUISelect
            names={{ label: "Select Booth", key: "boothId" }}
            state={dropdown}
            setState={setDropdown}
            disabled={!isNotNullOrEmpty(pollingBooths[0])}
          >
            {pollingBooths.map(({ id, name }, ind) => (
              <MenuItem key={ind} value={id} className="capitalize">
                {name}
              </MenuItem>
            ))}
          </MUISelect>
          <MUISelect
            names={{ label: "Select Position", key: "positionId" }}
            state={dropdown}
            setState={setDropdown}
          >
            {positions.map(({ id, name }, ind) => (
              <MenuItem key={ind} value={id} className="capitalize">
                {name}
              </MenuItem>
            ))}
          </MUISelect>
        </section>
        {isNotNullOrEmpty(voteCount[0]) && (
          <React.Fragment>
            <h3 className="text-center mt-4 mb-1">
              Total Voters: {totalVoters}
            </h3>
            <form onSubmit={uploadVoteCount}>
              <DataTable headCells={["S. No.", "Candidate Name", "Vote Count"]}>
                {voteCount.map((ele, ind) => {
                  total += +ele.vote_count;

                  return (
                    <React.Fragment key={ind}>
                      <TableRow>
                        <TableCell>{ind + 1}</TableCell>
                        <TableCell>
                          <h3 className="!m-0">{ele.candidate_name}</h3>
                        </TableCell>
                        <TableCell className="w-72">
                          <MUITextField
                            type="number"
                            names={{ label: "Vote Count", key: "vote_count" }}
                            state={ele}
                            custom={(val) => onChangeVal(val, ind)}
                            margin="!mb-0"
                          />
                        </TableCell>
                      </TableRow>
                      {ind === voteCount.length - 1 && (
                        <TableRow>
                          <TableCell colSpan={2} align="center">
                            <h3 className="!m-0">Total Vote Count</h3>
                          </TableCell>
                          <TableCell align="center">
                            <h3 className="!m-0">{total}</h3>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
              </DataTable>
              <div className="flex justify-end">
                <button className="btn btn-blue mt-6">
                  <SaveIcon className="!text-xl" />
                  Save
                </button>
              </div>
            </form>
          </React.Fragment>
        )}
      </section>
    </main>
  );
};

export default VotingResultEntry;
