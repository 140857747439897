import * as React from "react";
import { AppBar, Container, Toolbar } from "@mui/material";
import MobileBar from "./MobileBar";
import DesktopBar from "./DesktopBar";
import AvatarMenu from "./AvatarMenu";

function MUIAppBar({ menu, setAuth, username }) {
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Desktop */}
          <DesktopBar menu={menu} />

          {/* Mobile */}
          <MobileBar menu={menu} />

          {/* Avatar */}
          <AvatarMenu username={username} setAuth={setAuth} />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default MUIAppBar;
