import React from "react";

const Dashboard = () => {
  return (
    <main className="main">
      <h1 className="text-blue text-5xl font-medium tracking-tight">Welcome</h1>
    </main>
  );
};

export default Dashboard;
