import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const MUITextField = ({
  names,
  state,
  setState,
  required = true,
  type = "text",
  custom = false,
  margin = "!mb-3.5",
  readOnly = false,
  minLength = 0,
}) => {
  const [inputType, setInputType] = useState(false);
  const { label, key } = names;

  const onValChange = (e) => {
    let value = e.target.value;
    if (custom !== false) {
      custom(value);
    } else {
      setState({ ...state, [key]: value });
    }
  };

  return (
    <TextField
      variant="standard"
      size="small"
      type={type === "password" ? (inputType ? "text" : type) : type}
      InputLabelProps={{
        style: {
          marginLeft: "10px",
        },
      }}
      FormHelperTextProps={{
        className: "pt-1",
      }}
      InputProps={{
        style: {
          padding: "5px 10px",
        },
        readOnly,
        endAdornment: type === "password" && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setInputType(true)}
              onMouseDown={() => setInputType(false)}
              edge="end"
            >
              {inputType ? (
                <Visibility className="!text-xl" />
              ) : (
                <VisibilityOff className="!text-xl" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{ minLength }}
      className={"w-full h-fit shadow-custom shadow-grey " + margin}
      required={required}
      label={label}
      value={state[key]}
      onChange={onValChange}
    />
  );
};

export default MUITextField;
