import React, { useEffect, useState } from "react";
import {
  DataTable,
  MUIConfirmDialog,
  MUIDialogFull,
  MUIProgress,
  MUISelect,
  MUISwitch,
  MUITextField,
} from "../../components";
import { MenuItem, TableCell, TableRow } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  isNotNullOrEmpty,
  get,
  getPollingStations,
  decryptData,
  post,
  put,
  launchToast,
} from "../../functions";

const userObj = {
  id: 0,
  name: "",
  role: "",
  username: "",
  password: "",
  confirmPassword: "",
  polling_station_id: "",
  polling_booth_id: "",
  active: "",
};
const headCells = [
  "S. No.",
  "Name",
  "Username",
  "Station",
  "Booth",
  "Role",
  "Active",
  "Actions",
];
const confirmDialogObj = {
  open: false,
  message: "",
  buttonName: "",
  function: () => {},
};

const Users = () => {
  const [userList, setUserList] = useState([]);
  const [user, setUser] = useState(userObj);
  const [confirmDialog, setConfirmDialog] = useState(confirmDialogObj);
  const [pollingStations, setPollingStations] = useState([]);
  const [pollingBooths, setPollingBooths] = useState([]);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [dialogDetails, setDialogDetails] = useState({
    open: false,
    buttonName: "User",
    heading: "Add User",
  });

  const getUserList = async (token) => {
    const response = await get("/security/user/list", token);
    if (response?.status === "Success") {
      setUserList(response.data);
    }
  };

  const getLookups = async () => {
    const { token } = decryptData();
    await getPollingStations(setPollingStations, token);
    await getUserList(token);
    setToken(token);
    setLoading(false);
  };

  useEffect(() => {
    getLookups();
  }, []);

  useEffect(() => {
    if (isNotNullOrEmpty(user.polling_station_id)) {
      pollingStations.map(
        (ele) =>
          ele.id === user.polling_station_id &&
          setPollingBooths(ele.polling_booth)
      );
    }
  }, [user.polling_station_id]);

  const addUser = async (obj) => {
    const response = await post("/security/user", obj);
    if (response?.status === "Success") {
      setUser(userObj);
      getUserList(token);
      setDialogDetails({ ...dialogDetails, open: false });
      launchToast("success", response.message);
    }
    setLoading(false);
  };

  const validateUserData = (e) => {
    e.preventDefault();
    const { name, role, username, password, confirmPassword } = user;
    if (password !== confirmPassword) {
      return launchToast(
        "warn",
        "Your passwords don't match. Please try again"
      );
    }
    setLoading(true);
    addUser({
      name,
      role,
      username,
      password,
      password_confirmation: confirmPassword,
      polling_booth_id: role === "user" ? user.polling_booth_id : null,
    });
  };

  const openAddUserDialog = () => {
    if (user.id !== 0) {
      setUser(userObj);
    }
    setDialogDetails({
      ...dialogDetails,
      heading: "Add User",
      open: true,
    });
  };

  const editUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { id, name, role, username, active } = user;
    const response = await put("/security/user", {
      id,
      name,
      role,
      username,
      active,
      polling_booth_id: role === "user" ? user.polling_booth_id : null,
    });
    if (response?.status === "Success") {
      setUser(userObj);
      getUserList(token);
      setDialogDetails({ ...dialogDetails, open: false });
      launchToast("success", response.message);
    }
    setLoading(false);
  };

  const openConfirmDialog = ({ target }, { name, role, id }) => {
    let { checked } = target;
    let action = checked ? "activate" : "deactivate";
    setConfirmDialog({
      open: true,
      buttonName: action.toUpperCase(),
      message: `${action} ${role} named ${name}`,
      function: () => activeOrInactiveUser(id, checked ? 1 : 0),
    });
  };

  const activeOrInactiveUser = async (userId, value) => {
    setConfirmDialog(confirmDialogObj);
    setLoading(true);
    const response = await get(`/security/user/lock/${userId}/${value}`);
    if (response?.status) {
      userList.map((ele, ind) => {
        if (ele.id === userId) {
          userList[ind].active = value;
        }
      });
      setUserList([...userList]);
      launchToast("success", response.message);
    }
    setLoading(false);
  };

  const openEditUserDialog = (ind) => {
    setUser(userList[ind]);
    setDialogDetails({
      ...dialogDetails,
      heading: "Edit User",
      open: true,
    });
  };

  if (loading) {
    return <MUIProgress />;
  }

  return (
    <main className="main !items-start">
      <section className="overflow-auto my-10">
        <h2 className="heading">User List</h2>
        <DataTable headCells={headCells}>
          {userList.map((ele, ind) => {
            return (
              <TableRow key={ind} className="*:!text-base">
                <TableCell align="center">{ind + 1}</TableCell>
                <TableCell className="whitespace-nowrap">{ele.name}</TableCell>
                <TableCell className="whitespace-nowrap">
                  {ele.username}
                </TableCell>
                <TableCell className="whitespace-nowrap">
                  {isNotNullOrEmpty(ele.polling_station_id)
                    ? ele.polling_station_name
                    : "No Station Associated"}
                </TableCell>
                <TableCell className="whitespace-nowrap">
                  {isNotNullOrEmpty(ele.polling_booth_id)
                    ? ele.polling_booth_name
                    : "No Booth Associated"}
                </TableCell>
                <TableCell className="whitespace-nowrap">{ele.role}</TableCell>
                <TableCell>
                  <MUISwitch
                    checked={ele.active}
                    onChangeFunc={(e) => openConfirmDialog(e, ele)}
                  />
                </TableCell>
                <TableCell>
                  <button
                    className="btn btn-blue !p-[3px] mx-auto !rounded-md !shadow-[0_2px_5px_0] !shadow-darkgrey"
                    onClick={() => openEditUserDialog(ind)}
                  >
                    <EditIcon className="!text-xl" />
                  </button>
                </TableCell>
              </TableRow>
            );
          })}
        </DataTable>
        <MUIDialogFull
          openDialog={openAddUserDialog}
          dialogDetails={dialogDetails}
          setDialogDetails={setDialogDetails}
          formSubmitFunc={
            dialogDetails.heading === "Add User" ? validateUserData : editUser
          }
          size="xs"
        >
          <section
            className={`grid gap-[3.4%] grid-cols-1 mt-2 ${
              user.role === "user" && dialogDetails.heading === "Add User"
                ? "mb-24"
                : "mb-10"
            }`}
          >
            <MUITextField
              names={{ label: "Name", key: "name" }}
              state={user}
              setState={setUser}
            />
            <MUISelect
              names={{ label: "Role", key: "role" }}
              state={user}
              setState={setUser}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="manager">Manager</MenuItem>
              <MenuItem value="user">User</MenuItem>
            </MUISelect>
            {user.role === "user" && (
              <React.Fragment>
                <MUISelect
                  names={{ label: "Station", key: "polling_station_id" }}
                  state={user}
                  setState={setUser}
                >
                  {pollingStations.map(({ id, name }, ind) => (
                    <MenuItem value={id} key={ind} className="capitalize">
                      {name}
                    </MenuItem>
                  ))}
                </MUISelect>
                <MUISelect
                  names={{ label: "Booth", key: "polling_booth_id" }}
                  state={user}
                  setState={setUser}
                  disabled={!isNotNullOrEmpty(pollingBooths[0])}
                >
                  {pollingBooths.map(({ id, name }, ind) => (
                    <MenuItem value={id} key={ind} className="capitalize">
                      {name}
                    </MenuItem>
                  ))}
                </MUISelect>
              </React.Fragment>
            )}
            <MUITextField
              names={{ label: "Username", key: "username" }}
              state={user}
              setState={setUser}
            />
            {dialogDetails.heading !== "Edit User" && (
              <React.Fragment>
                <MUITextField
                  type="password"
                  names={{ label: "Password", key: "password" }}
                  state={user}
                  setState={setUser}
                  minLength={6}
                />
                <MUITextField
                  type="password"
                  names={{ label: "Confirm Password", key: "confirmPassword" }}
                  state={user}
                  setState={setUser}
                  minLength={6}
                />
              </React.Fragment>
            )}
          </section>
        </MUIDialogFull>
        <MUIConfirmDialog
          dialogDetails={confirmDialog}
          setDialogDetails={setConfirmDialog}
        />
      </section>
    </main>
  );
};

export default Users;
