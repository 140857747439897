import React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const Uploader = ({ state, setState }) => {
  return (
    <React.Fragment>
      <label className="btn btn-blue mt-4">
        <AttachFileIcon className="!text-xl -mr-1" />
        Select File
        <input
          type="file"
          hidden
          accept=".csv"
          onChange={(e) =>
            setState(e.target.files[0] !== undefined ? e.target.files[0] : {})
          }
        />
      </label>
      <p className="text-center my-2 ellipsis">
        {state.name !== undefined ? state.name : "No File Selected"}
      </p>
    </React.Fragment>
  );
};

export default Uploader;
