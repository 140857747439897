import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const MUIProgress = () => {
  return (
    <main className="main">
      <CircularProgress />
    </main>
  );
};

export default MUIProgress;
