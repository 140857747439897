import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { DialogActions, DialogContent } from "@mui/material";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { isNotNullOrEmpty } from "../../../functions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MUIDialogFull = ({
  dialogDetails,
  setDialogDetails,
  formSubmitFunc,
  children,
  openDialog,
  lockUnlockBtn = { display: false },
  size = "",
}) => {
  const { buttonName, heading, open } = dialogDetails;
  let locked = false;

  if (lockUnlockBtn.display) {
    locked = lockUnlockBtn.pollingStation.locked === 1;
  }

  return (
    <React.Fragment>
      <button
        className="btn btn-blue fixed bottom-3 right-3 !text-sm !rounded-full !pl-1.5"
        onClick={openDialog}
      >
        <AddIcon className="!text-xl -mr-1" />
        {buttonName}
      </button>
      <Dialog
        fullWidth={true}
        fullScreen={isNotNullOrEmpty(size) ? false : true}
        maxWidth={size}
        open={open}
        TransitionComponent={Transition}
        PaperProps={{
          component: "form",
          onSubmit: (e) => formSubmitFunc(e),
        }}
      >
        <AppBar sx={{ position: "relative" }} className="!p-0">
          <Toolbar>
            <Typography sx={{ flex: 1 }} variant="h6" component="div">
              {heading}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() =>
                setDialogDetails({ ...dialogDetails, open: false })
              }
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>{children}</DialogContent>
        <DialogActions
          className={`${
            lockUnlockBtn.display ? "!justify-between" : ""
          } shadow-[5px_0_10px_0] shadow-darkgrey bg-lightgrey !py-2.5 !pb-3.5`}
        >
          {lockUnlockBtn.display && (
            <button
              type="button"
              className={`btn ${locked ? "btn-green" : "btn-red"}`}
              onClick={lockUnlockBtn.function}
              disabled={!lockUnlockBtn.disabled}
            >
              {locked ? (
                <LockOpenIcon className="!text-xl mb-0.5" />
              ) : (
                <LockIcon className="!text-xl mb-0.5" />
              )}
              {locked ? "Unlock Polling Station" : "Lock Polling Station"}
            </button>
          )}
          <button className="btn btn-blue">
            <SaveIcon className="!text-xl mb-0.5" />
            Save
          </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default MUIDialogFull;
