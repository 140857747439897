import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const DataTable = ({ headCells, children }) => {
  return (
    <section className="flex justify-center">
      <TableContainer className="rounded border-[0.5px] border-grey shadow-custom shadow-grey m-5 !w-auto">
        <Table size="small" className="!w-auto">
          <TableHead>
            <TableRow className="bg-blue *:!text-white *:!text-base">
              {headCells.map((ele, ind) => (
                <TableCell key={ind}>{ele}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};

export default DataTable;
