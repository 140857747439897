import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import EditIcon from "@mui/icons-material/Edit";

const checkExpand = (expand, index) => {
  if (expand === index) {
    return (
      <RemoveCircleIcon className="!text-[21px] text-blue mr-2 mb-[1px]" />
    );
  } else {
    return <AddCircleIcon className="!text-[22px] text-blue mr-2 mb-[1px]" />;
  }
};

const actionBtns = (editFunc) => {
  return (
    <section className="!flex !justify-center">
      <button
        className="btn btn-blue !p-[3px] !rounded-md !shadow-[0_2px_5px_0] !shadow-darkgrey"
        onClick={editFunc}
      >
        <EditIcon className="!text-lg" />
      </button>
    </section>
  );
};

export { checkExpand, actionBtns };
