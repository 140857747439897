import React, { useEffect, useState } from "react";
import {
  MUIConfirmDialog,
  MUIDialogFull,
  MUIProgress,
  MUISelect,
  MUITable,
  MUITextField,
} from "../../components";
import { MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  decryptData,
  deleteData,
  get,
  getPollingStations,
  isNotNullOrEmpty,
  post,
  launchToast,
} from "../../functions";

const pollingStationObj = {
  id: 0,
  code: "",
  name: "",
  address: "",
};
const confirmDialogObj = {
  open: false,
  message: "",
  buttonName: "",
  function: () => {},
};

const PollingStations = () => {
  const [pollingStation, setPollingStation] = useState(pollingStationObj);
  const [pollingBooths, setPollingBooths] = useState([
    {
      id: 0,
      code: "",
      name: "",
      gender: "",
    },
  ]);
  const [loading, setLoading] = useState(true);
  const [confirmDialog, setConfirmDialog] = useState(confirmDialogObj);
  const [token, setToken] = useState("");
  const [bodyCells, setBodyCells] = useState([]);
  // const headCells = ["Code", "Name", "Address", "Action"];
  const headCells = ["Code", "Name", "Status", "Action"];
  const [status, setStatus] = useState({});
  const [dialogDetails, setDialogDetails] = useState({
    open: false,
    buttonName: "Station",
    heading: "Add Polling Station",
  });

  const getElectoralStatus = async (token) => {
    const response = await get("/config/election/status", token);
    if (response?.status === "Success") {
      setStatus(response.data);
    }
  };

  const getLookups = async () => {
    const { token } = decryptData();
    await getElectoralStatus(token);
    await getPollingStations(setBodyCells, token);
    setToken(token);
    setLoading(false);
  };

  useEffect(() => {
    getLookups();
  }, []);

  const addOrEditPollingStation = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await post("/pollingstation", {
      ...pollingStation,
      polling_booth: pollingBooths,
    });
    if (response?.status === "Success") {
      setPollingStation(pollingStationObj);
      setPollingBooths([
        {
          id: 0,
          code: "",
          name: "",
          gender: "",
        },
      ]);
      await getPollingStations(setBodyCells, token);
      setDialogDetails({ ...dialogDetails, open: false });
      launchToast("success", response.message);
    }
    setLoading(false);
  };

  const addPollingBooth = () => {
    setPollingBooths([
      ...pollingBooths,
      {
        id: 0,
        code: "",
        name: "",
        gender: "",
      },
    ]);
  };

  const openAddStationDialog = () => {
    setPollingStation(pollingStationObj);
    setPollingBooths([
      {
        id: 0,
        code: "",
        name: "",
        gender: "",
      },
    ]);
    setDialogDetails({
      ...dialogDetails,
      heading: "Add Polling Station",
      open: true,
    });
  };

  const deleteBooth = (ind) => {
    pollingBooths.splice(ind, 1);
    setPollingBooths([...pollingBooths]);
  };

  const permanantlyDeleteBooth = async (ind, id) => {
    setLoading(true);
    setConfirmDialog(confirmDialogObj);
    const response = await deleteData(
      `/pollingstation/pollingbooth/${id}`,
      token
    );
    if (response?.status === "Success") {
      deleteBooth(ind);
      await getPollingStations(setBodyCells, token);
      launchToast("success", response.message);
    }
    setLoading(false);
  };

  const checkBoothNo = ({ id }, ind) => {
    if (pollingBooths.length === 1) {
      return launchToast("warn", "Atleast one polling booth is manadatory");
    }
    if (id === 0) {
      deleteBooth(ind);
    } else {
      setConfirmDialog({
        open: true,
        buttonName: "DELETE",
        message: `permanently delete Polling Booth #${ind}`,
        function: () => permanantlyDeleteBooth(ind, id),
      });
    }
  };

  const setPollingBoothsVal = (val, key, ind) => {
    pollingBooths[ind][key] = val;
    setPollingBooths([...pollingBooths]);
  };

  const openEditStationDialog = (ind) => {
    setDialogDetails({
      ...dialogDetails,
      open: true,
      heading: "Edit Polling Station",
    });
    setPollingStation({
      id: bodyCells[ind].id,
      code: bodyCells[ind].code,
      name: bodyCells[ind].name,
      locked: bodyCells[ind].locked,
      address: bodyCells[ind].address,
    });
    setPollingBooths(bodyCells[ind].polling_booth);
  };

  const lockUnlockStation = async (id, value) => {
    setLoading(true);
    setConfirmDialog(confirmDialogObj);
    const response = await get(`/pollingstation/lock/${id}/${value}`);
    if (response?.status === "Success") {
      await getPollingStations(setBodyCells, token);
      setPollingStation({ ...pollingStation, locked: value });
      launchToast("success", response.message);
    }
    setLoading(false);
  };

  const openConfirmDialog = () => {
    const { id, name, locked } = pollingStation;
    let action = locked === 1 ? "unlock" : "lock";
    let lockMsg = `disconnect ${name} polling station users and will interrupt Election activity`;
    let unlockMsg = `allow ${name} polling station users to connect and start Election activity`;
    setConfirmDialog({
      open: true,
      buttonName: action.toUpperCase(),
      message: locked === 1 ? unlockMsg : lockMsg,
      function: () => lockUnlockStation(id, locked === 1 ? 0 : 1),
    });
  };

  if (loading) {
    return <MUIProgress />;
  }

  return (
    <main className="main !items-start">
      <section className="my-10 overflow-auto">
        <h2 className="heading">Polling Stations</h2>
        <MUITable
          headCells={headCells}
          bodyCells={bodyCells}
          openEditStationDialog={openEditStationDialog}
        />
      </section>
      <MUIDialogFull
        lockUnlockBtn={{
          display: dialogDetails.heading === "Edit Polling Station",
          disabled: status.status === "S",
          function: openConfirmDialog,
          pollingStation,
        }}
        openDialog={openAddStationDialog}
        dialogDetails={dialogDetails}
        setDialogDetails={setDialogDetails}
        formSubmitFunc={addOrEditPollingStation}
      >
        <section className="pt-4">
          <div className="grid gap-x-[3.4%] grid-cols-1 sm:grid-cols-3">
            <MUITextField
              names={{ label: "Code", key: "code" }}
              state={pollingStation}
              setState={setPollingStation}
            />
            <MUITextField
              names={{ label: "Name", key: "name" }}
              state={pollingStation}
              setState={setPollingStation}
            />
            <MUITextField
              names={{ label: "Address", key: "address" }}
              state={pollingStation}
              setState={setPollingStation}
            />
          </div>
          <h2 className="heading mt-5 !mb-3 !text-left">
            {dialogDetails.heading[0] === "A"
              ? "Add Polling Booth"
              : "Edit Polling Booth"}
          </h2>
          {pollingBooths.map((ele, ind) => (
            <div
              key={ind}
              className="grid gap-x-[3.4%] grid-cols-1 sm:grid-cols-[1fr_1fr_1fr_44px]"
            >
              <h4 className="col-span-1 sm:col-span-4 my-2.5">
                Polling Booth #{ind + 1}:
              </h4>
              <MUITextField
                names={{ label: "Code", key: "code" }}
                state={ele}
                custom={(val) => setPollingBoothsVal(val, "code", ind)}
              />
              <MUITextField
                names={{ label: "Name", key: "name" }}
                state={ele}
                custom={(val) => setPollingBoothsVal(val, "name", ind)}
              />
              <MUISelect
                names={{ label: "Gender", key: "gender" }}
                state={ele}
                custom={(val) => setPollingBoothsVal(val, "gender", ind)}
              >
                <MenuItem value="M">Male</MenuItem>
                <MenuItem value="F">Female</MenuItem>
              </MUISelect>
              <button
                className="btn btn-blue mb-5 mt-2 !rounded-full"
                type="button"
                onClick={() => checkBoothNo(ele, ind)}
              >
                <DeleteIcon className="!text-[22px] mb-0.5" />
                <span className="sm:hidden">Delete Polling Booth</span>
              </button>
            </div>
          ))}
          <button
            className="btn btn-blue my-5 max-sm:w-full"
            type="button"
            onClick={addPollingBooth}
          >
            <AddIcon className="-mr-1 !text-xl" /> Add Polling Booth
          </button>
        </section>
      </MUIDialogFull>
      <MUIConfirmDialog
        dialogDetails={confirmDialog}
        setDialogDetails={setConfirmDialog}
      />
    </main>
  );
};

export default PollingStations;
