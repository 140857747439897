import React, { forwardRef } from "react";
import ReactInputMask from "react-input-mask";
import SearchIcon from "@mui/icons-material/Search";
import {
  checkMembershipNoMask,
  extractNumber,
  getHelperText,
} from "../../functions";

const SearchBar = forwardRef(
  ({ names, state, setState, type, error = false }, ref) => {
    const { label, key } = names;
    let helperTextColor = error ? "text-red" : "text-stone-600";
    let inputTextColor = error ? "text-red" : "text-black";

    const onChangeVal = (e) => {
      setState({ ...state, [key]: extractNumber(e) });
    };

    return (
      <div className="mb-5">
        <section className="shadow-custom shadow-grey rounded flex justify-center mt-4 mb-3">
          <ReactInputMask
            mask={checkMembershipNoMask(type)}
            placeholder={label}
            value={state[key]}
            required={true}
            onChange={onChangeVal}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                type="text"
                ref={ref}
                className={
                  "outline-none py-3 px-3.5 rounded-l w-full " + inputTextColor
                }
              />
            )}
          </ReactInputMask>
          <button className="btn btn-blue !rounded-l-none !rounded-r !shadow-none !px-2 !hover:opacity-70">
            <SearchIcon />
          </button>
        </section>
        <p className={"text-sm ml-1 " + helperTextColor}>
          {getHelperText(type)}
        </p>
      </div>
    );
  }
);

export default SearchBar;
