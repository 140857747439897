import React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MUIMenu = ({ moduleName, module, setState = false }) => {
  const [menu, setMenu] = React.useState(null);
  const navigate = useNavigate();
  const open = Boolean(menu);

  const navNavigation = (routeUrl) => {
    navigate(`${routeUrl}`);
    setMenu(null);
    setState && setState(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => setMenu(e.currentTarget)}
        color="inherit"
        sx={{
          display: "block",
        }}
      >
        {moduleName}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={menu}
        open={open}
        onClose={() => {
          setMenu(null);
          setState && setState(null);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {module.map(({ name, route }, ind) => (
          <MenuItem key={ind} onClick={() => navNavigation(route)}>
            {name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MUIMenu;
