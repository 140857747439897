import axios from "axios";
import {
  BASE_URI,
  browserStorageDataKey,
  browserStorageEncrKey,
} from "../core";
import * as CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import dayjs from "dayjs";

// const getMaxDate = () => {
//   const date = new Date();
//   date.setFullYear(date.getFullYear() - 18);
//   console.log("Here is the bug");
//   return dayjs(date);
// };

const reset = (flag = true) => {
  localStorage.clear();
  flag && window.location.reload();
};

const decryptData = (flag = true) => {
  const key = localStorage.getItem(browserStorageDataKey);

  if (key !== "" && key !== undefined && key !== null) {
    let bytes = CryptoJS.AES.decrypt(key, browserStorageEncrKey);
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    const { token } = decryptedData[0];
    if (token !== undefined && token !== null && token !== "") {
      // console.log(decryptedData[0]);
      return { ...decryptedData[0], status: "Success" };
    } else {
      reset(flag);
    }
  } else {
    flag && window.location.reload();
  }
};

const getHeaders = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${
        isNotNullOrEmpty(token) ? token : decryptData().token
      }`,
    },
  };
};

const handleError = (error) => {
  // console.log(error);
  if (error.response.status === 500) {
    launchToast("error", "Unexpected Error on Server");
    return { status: "Error" };
  }
  if (
    error.response.data.message === "Unauthorized" ||
    error.response.data.message === "Unauthenticated." ||
    error.response.data.message.slice(0, 19) === "Session terminated."
  ) {
    alert(error.response.data.message);
    reset();
  } else {
    launchToast("error", error.response.data.message);
  }
  return error.response.data;
};

const post = async (url, body, flag = true) => {
  try {
    const response = await axios.post(
      BASE_URI + url,
      body,
      flag && getHeaders()
    );
    // console.log(response?.data);
    return response?.data;
  } catch (error) {
    return handleError(error);
  }
};

const put = async (url, body) => {
  try {
    const response = await axios.put(BASE_URI + url, body, getHeaders());
    // console.log(response?.data);
    return response?.data;
  } catch (error) {
    return handleError(error);
  }
};

const deleteData = async (url, token = "") => {
  try {
    const response = await axios.delete(BASE_URI + url, getHeaders(token));
    // console.log(response?.data);
    return response?.data;
  } catch (error) {
    handleError(error);
  }
};

const get = async (url, token = "") => {
  try {
    const response = await axios.get(BASE_URI + url, getHeaders(token));
    // console.log(response?.data);
    return response?.data;
  } catch (error) {
    handleError(error);
  }
};

const isNotNullOrEmpty = (variable) => {
  return variable !== "" && variable !== null && variable !== undefined;
};

const getPollingStations = async (setPollingStations, token) => {
  const response = await get("/pollingstation/list", token);
  if (response?.status === "Success") {
    setPollingStations(response.data);
  }
};

// Membership# Functions

const checkDocNoLength = (type) => {
  if (type === "NIC") {
    return 13;
  } else if (type === "JCIC") {
    return 16;
  } else if (type === "JID") {
    return 11;
  }
};

const checkMembershipNoMask = (type) => {
  if (type === "NIC") {
    return "99999-9999999-9";
  } else if (type === "JCIC") {
    return "9999 9999 9999 9999";
  } else if (type === "JID") {
    return "99999-999-99-a";
  }
};

const getHelperText = (type) => {
  return "Minimum length should be " + checkDocNoLength(type);
};

const extractNumber = (e) => {
  let value = e.target.value;
  value = value.replaceAll("_", "");
  value = value.replaceAll("-", "");
  return value.replaceAll(" ", "");
};

const checkMembershipNoLength = (type, num) => {
  return num.length !== checkDocNoLength(type);
};

const getPakistanDataTime = (date) => {
  return dayjs(date).format("DD-MMM-YYYY, hh:mm:ss A");
};

const launchToast = (status, message) => {
  toast.dismiss();
  switch (status) {
    case "success":
      toast.success(message);
      break;
    case "info":
      toast.info(message);
      break;
    case "warn":
      toast.warn(message, { autoClose: false });
      break;
    case "error":
      toast.error(message, { autoClose: false });
      break;
    default:
      break;
  }
};

const getCopyrightYear = () => {
  return new Date().getFullYear();
};

export {
  // getMaxDate,
  get,
  post,
  put,
  deleteData,
  handleError,
  launchToast,
  decryptData,
  getHelperText,
  extractNumber,
  isNotNullOrEmpty,
  getCopyrightYear,
  checkDocNoLength,
  getPollingStations,
  getPakistanDataTime,
  checkMembershipNoMask,
  checkMembershipNoLength,
};
