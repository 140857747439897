import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MUITableRow from "./MUITableRow";
import { isNotNullOrEmpty } from "../../../functions";

const MUITable = ({ headCells, bodyCells, openEditStationDialog }) => {
  return (
    <div className="m-5">
      <TableContainer className="rounded border-[0.5px] border-grey shadow-custom shadow-grey">
        <Table size="small">
          <TableHead>
            <TableRow className="bg-blue">
              {headCells.map((ele, ind) => (
                <TableCell
                  key={ind}
                  align="center"
                  className="!text-white !text-base"
                >
                  {ele}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isNotNullOrEmpty(bodyCells[0]) ? (
              bodyCells.map((rowData, index) => (
                <MUITableRow
                  key={index}
                  rowData={rowData}
                  index={index}
                  openEditStationDialog={openEditStationDialog}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center" className="!text-base">
                  No Polling Stations Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MUITable;
