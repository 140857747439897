import React, { useEffect, useState } from "react";
import { DataTable, MUIProgress } from "../../components";
import { handleError } from "../../functions";
import { AuthKey, BASE_URI } from "../../core";
import { TableCell, TableRow } from "@mui/material";
import axios from "axios";

const VoterCount = () => {
  const [voterCount, setVoterCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const headCells = ["S. No.", "Station", "Count"];
  let total = 0;

  const getVoterCount = async () => {
    try {
      const response = await axios.get(`${BASE_URI}/public/voter-count`, {
        headers: { Authorization: AuthKey },
      });
      if (response?.data?.status === "Success") {
        setVoterCount(response.data.data);
      }
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getVoterCount();
  }, []);

  if (loading) {
    return <MUIProgress />;
  }

  return (
    <main className="main !items-start">
      <section className=" my-10">
        <h2 className="heading">Voter Count</h2>
        <DataTable headCells={headCells}>
          {voterCount.map(({ polling_station_name, voter_count }, ind) => {
            total += voter_count;
            return (
              <TableRow key={ind} className="*:!text-base">
                <TableCell align="center">{ind + 1}</TableCell>
                <TableCell>{polling_station_name}</TableCell>
                <TableCell align="center">{voter_count}</TableCell>
              </TableRow>
            );
          })}
          <TableRow className="*:!text-base">
            <TableCell colSpan={2}>Total</TableCell>
            <TableCell align="center">{total}</TableCell>
          </TableRow>
        </DataTable>
      </section>
    </main>
  );
};

export default VoterCount;
