import React from "react";
import { Box, IconButton, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { logo } from "../../../assets";
import MUIMenu from "../MUIMenu";

const MobileBar = ({ menu }) => {
  const [navMenu, setNavMenu] = React.useState(null);
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "flex", md: "none" },
        }}
      >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={(e) => setNavMenu(e.currentTarget)}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={navMenu}
          open={Boolean(navMenu)}
          onClose={() => setNavMenu(null)}
          sx={{
            display: { xs: "block", md: "none" },
          }}
        >
          {menu.map((ele, ind) => (
            <MUIMenu
              key={ind}
              moduleName={ele.name}
              module={ele.options}
              setState={setNavMenu}
            />
          ))}
        </Menu>
      </Box>
      {/* logo */}
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "flex", md: "none" },
          cursor: "pointer",
        }}
        onClick={() => navigate("/")}
      >
        <img src={logo} className="w-28 my-1" alt="election_img" />
      </Box>
    </>
  );
};

export default MobileBar;
