import React, { useEffect, useState } from "react";
import * as CryptoJS from "crypto-js";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { MUITextField } from "../../components";
import { loginImg } from "../../assets";
import { getCopyrightYear, post } from "../../functions";
import { browserStorageDataKey, browserStorageEncrKey } from "../../core";
import { useNavigate } from "react-router-dom";
import packageJson from "../../../package.json";

const Login = ({ auth, setAuth }) => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(
      JSON.stringify([data]),
      browserStorageEncrKey
    ).toString();
  };

  const authenticateUser = async (e) => {
    e.preventDefault();
    const response = await post("/auth/login", credentials, false);
    if (response?.status === "Success") {
      setCredentials({
        username: "",
        password: "",
      });
      localStorage.setItem(browserStorageDataKey, encryptData(response.data));
      navigate("/");
      setAuth({ condition: true, lastUpdatedOn: "Login" });
    }
  };

  useEffect(() => {
    if (auth.condition) {
      navigate("/");
    }
  }, [auth]);

  return (
    <main className="main !h-screen">
      <section className="bg-white w-80 rounded-md shadow-[0_5px_50px_0] shadow-grey">
        <img src={loginImg} className="h-56 rounded-t-md" alt="login_image" />
        <section className="px-6 pt-7 rounded-b-md">
          <h2 className="text-darkblue text-3xl">Login</h2>
          <form onSubmit={authenticateUser} className="mt-6">
            <MUITextField
              names={{ label: "Username", key: "username" }}
              state={credentials}
              setState={setCredentials}
            />
            <MUITextField
              type="password"
              names={{ label: "Password", key: "password" }}
              state={credentials}
              setState={setCredentials}
            />
            <button className="btn btn-blue w-full mt-2">
              <LockOpenIcon className="!text-xl" />
              Login
            </button>
          </form>
          <section className="w-full mt-5 mb-3 text-darkblue font-medium text-sm flex justify-between">
            <span>© {getCopyrightYear()} KPSIAJ</span>
            <span>Version: {packageJson.version}</span>
          </section>
        </section>
      </section>
    </main>
  );
};

export default Login;
