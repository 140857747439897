import React, { useEffect, useState } from "react";
import {
  DataTable,
  MUIDialogFull,
  MUIProgress,
  MUISelect,
  MUITextField,
} from "../../components";
import { MenuItem, TableCell, TableRow } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  isNotNullOrEmpty,
  get,
  decryptData,
  post,
  launchToast,
} from "../../functions";

const candidateObj = {
  id: 0,
  name: "",
  position_id: "",
};
const headCells = ["S. No.", "Name", "Position", "Actions"];

const Candidates = () => {
  const [candidateList, setCandidateList] = useState([]);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [positions, setPositions] = useState([]);
  const [positionId, setPositionId] = useState({ id: 0 });
  const [candidate, setCandidate] = useState(candidateObj);
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [dialogDetails, setDialogDetails] = useState({
    open: false,
    buttonName: "Candidate",
    heading: "Add Candidate",
  });

  const getCandidateList = async (token) => {
    const response = await get("/lookup/candidate", token);
    if (response?.status === "Success") {
      let candidateArr = [];
      response.data.map(({ id, name, position_id, position }) => {
        candidateArr.push({
          id,
          name,
          position_id,
          position_name: position.name,
          display_order: position.display_order,
        });
      });
      candidateArr.sort((a, b) => {
        if (a.display_order < b.display_order) {
          return -1;
        }
      });
      setPositionId({ id: 0 });
      setCandidateList(candidateArr);
      setFilteredCandidates(candidateArr);
    }
  };

  const getPositions = async (token) => {
    const response = await get("/lookup/position", token);
    if (response?.status === "Success") {
      setPositions(response.data);
    }
  };

  const getLookups = async () => {
    const { token } = decryptData();
    await getCandidateList(token);
    await getPositions(token);
    setToken(token);
    setLoading(false);
  };

  useEffect(() => {
    getLookups();
  }, []);

  const filterCandidates = (positionId) => {
    let candidatesArr = [];
    if (positionId === 0) {
      candidatesArr = candidateList;
    } else {
      candidatesArr = candidateList.filter(
        (ele) => ele.position_id === positionId
      );
    }
    setPositionId({ id: positionId });
    setFilteredCandidates(candidatesArr);
  };

  const openAddCandidateDialog = () => {
    if (candidate.id !== 0) {
      setCandidate(candidateObj);
    }
    setDialogDetails({
      ...dialogDetails,
      heading: "Add Candidate",
      open: true,
    });
  };

  const openEditCandidateDialog = (ind) => {
    const { id, name, position_id } = filteredCandidates[ind];
    setCandidate({ id, name, position_id });
    setDialogDetails({
      ...dialogDetails,
      heading: "Edit Candidate",
      open: true,
    });
  };

  const addOrEditCandidate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await post("/lookup/candidate", candidate);
    if (response?.status === "Success") {
      setCandidate(candidateObj);
      getCandidateList(token);
      setDialogDetails({ ...dialogDetails, open: false });
      launchToast("success", response.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <MUIProgress />;
  }

  return (
    <main className="main !items-start">
      <section className="overflow-auto my-10">
        <h2 className="heading">Candidate List</h2>
        <section className="ml-5 w-72">
          <MUISelect
            names={{ label: "Position", key: "id" }}
            state={positionId}
            custom={filterCandidates}
            required={false}
          >
            <MenuItem value={0}>All</MenuItem>
            {positions.map(({ id, name }, ind) => (
              <MenuItem key={ind} value={id} className="capitalize">
                {name}
              </MenuItem>
            ))}
          </MUISelect>
        </section>
        <DataTable headCells={headCells}>
          {!isNotNullOrEmpty(filteredCandidates[0]) && (
            <TableRow>
              <TableCell colSpan={4} className="!text-base !text-center">
                Candidates Not Found
              </TableCell>
            </TableRow>
          )}
          {filteredCandidates.map(({ name, position_name }, ind) => (
            <TableRow key={ind} className="*:!text-base">
              <TableCell align="center">{ind + 1}</TableCell>
              <TableCell className="whitespace-nowrap">{name}</TableCell>
              <TableCell className="whitespace-nowrap">
                {position_name}
              </TableCell>
              <TableCell>
                <button
                  className="btn btn-blue !p-[3px] mx-auto !rounded-md !shadow-[0_2px_5px_0] !shadow-darkgrey"
                  onClick={() => openEditCandidateDialog(ind)}
                >
                  <EditIcon className="!text-xl" />
                </button>
              </TableCell>
            </TableRow>
          ))}
        </DataTable>
        <MUIDialogFull
          openDialog={openAddCandidateDialog}
          dialogDetails={dialogDetails}
          setDialogDetails={setDialogDetails}
          formSubmitFunc={addOrEditCandidate}
          size="xs"
        >
          <section className="grid gap-[3.4%] grid-cols-1 mt-2 mb-24">
            <MUITextField
              names={{ label: "Name", key: "name" }}
              state={candidate}
              setState={setCandidate}
            />
            <MUISelect
              names={{ label: "Position", key: "position_id" }}
              state={candidate}
              setState={setCandidate}
            >
              {positions.map(({ id, name }, ind) => (
                <MenuItem key={ind} value={id} className="capitalize">
                  {name}
                </MenuItem>
              ))}
            </MUISelect>
          </section>
        </MUIDialogFull>
      </section>
    </main>
  );
};

export default Candidates;
