import React from "react";
import { TableCell } from "@mui/material";
import { actionBtns, checkExpand } from "../../../functions";
import style from "../../components.module.css";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const MUIExpandedCell = ({ rowData, index, expand, openEditStationDialog }) => {
  return (
    <TableCell key={index} colSpan={4}>
      <table className={style.dataTable}>
        <thead>
          <tr>
            <td className="w-[102px] !pr-4">
              <p className="whitespace-nowrap">
                {checkExpand(expand, index)}
                {rowData.code}
              </p>
            </td>
            <td className="whitespace-nowrap !px-4">{rowData.name}</td>
            <td className="w-[80px]">
              <div className="flex justify-center">
                {rowData.locked === 1 ? (
                  <LockIcon className="!text-blue" />
                ) : (
                  <LockOpenIcon className="!text-blue" />
                )}
              </div>
            </td>
            <td className="w-[70px] *:!justify-end *:pr-3">
              {actionBtns(() => openEditStationDialog(index))}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4}>
              <p className="text-[17px] font-semibold">Polling Booths:</p>
            </td>
          </tr>
          <tr>
            <td>Code</td>
            <td colSpan={2} className="!pl-4">
              Name
            </td>
            <td>Gender</td>
          </tr>
          {rowData.polling_booth.map((ele, ind) => (
            <tr key={ind} className="*:whitespace-nowrap">
              <td>{ele.code}</td>
              <td colSpan={2} className="!pl-4">
                {ele.name}
              </td>
              <td>{ele.gender}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableCell>
  );
};

export default MUIExpandedCell;
