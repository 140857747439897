import React from "react";
import ReactInputMask from "react-input-mask";
import { TextField } from "@mui/material";
import {
  checkMembershipNoMask,
  extractNumber,
  getHelperText,
} from "../../functions";

const MaskedInput = ({ type, names, state, setState, error = false }) => {
  const { key, label } = names;
  let helperTextClass = error ? "text-red" : "text-stone-600";

  const onChangeVal = (e) => {
    setState({ ...state, [key]: extractNumber(e) });
  };

  return (
    <ReactInputMask
      mask={checkMembershipNoMask(type)}
      value={state[key]}
      onChange={onChangeVal}
    >
      {(inputProps) => (
        <div className="mb-3.5">
          <TextField
            {...inputProps}
            id="standard-basic"
            variant="standard"
            label={label}
            type="text"
            required={true}
            InputLabelProps={{
              style: {
                marginLeft: "10px",
              },
            }}
            FormHelperTextProps={{
              className: "pt-1",
            }}
            InputProps={{
              style: {
                padding: "5px 10px",
              },
            }}
            error={error}
            className="w-full h-fit shadow-custom shadow-grey !mb-2"
          />
          <p className={"text-sm " + helperTextClass}>{getHelperText(type)}</p>
        </div>
      )}
    </ReactInputMask>
  );
};

export default MaskedInput;
