import React, { useState } from "react";
import {
  // MUIDatePicker,
  MUIProgress,
  MUISelect,
  MUITextField,
  MaskedInput,
} from "../../../components";
import { MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { checkMembershipNoLength, post, launchToast } from "../../../functions";

const errorObj = {
  cnicNo: false,
  jcicNo: false,
  jidNo: false,
};
const memberObj = {
  name: "",
  fatherAndHusbandName: "",
  surname: "",
  cnicNo: "",
  membershipNo: "",
  jcicNo: "",
  jidNo: "",
  gender: "",
  age: "",
  remarks: "",
};

const MemberEntry = () => {
  const [member, setMember] = useState(memberObj);
  const [error, setError] = useState(errorObj);
  const [loading, setLoading] = useState(false);

  const handleNumberLength = (errorObject) => {
    const { cnicNo, jcicNo, jidNo } = member;
    errorObject.cnicNo = checkMembershipNoLength("NIC", cnicNo);
    errorObject.jcicNo =
      checkMembershipNoLength("JCIC", jcicNo) &&
      member.membershipNo !== "jidNo";
    errorObject.jidNo =
      checkMembershipNoLength("JID", jidNo) && member.membershipNo !== "jcicNo";
    // console.log(errorObject);
    setError(errorObject);
  };

  const addMember = async (e) => {
    e.preventDefault();
    let errorObject = {
      cnicNo: false,
      jcicNo: false,
      jidNo: false,
    };
    // console.log({
    //   Name: member.name,
    //   Father_Husband_Name: member.fatherAndHusbandName,
    //   Surname: member.surname,
    //   JID: member.jidNo,
    //   JCIC: member.jcicNo,
    //   NIC: member.cnicNo,
    //   Gender: member.gender,
    //   Age: member.age,
    //   Remarks: member.remarks,
    // });
    handleNumberLength(errorObject);

    if (!errorObject.cnicNo && !errorObject.jcicNo && !errorObject.jidNo) {
      setLoading(true);
      const response = await post("/member/create", {
        Name: member.name,
        Father_Husband_Name: member.fatherAndHusbandName,
        Surname: member.surname,
        JID: member.jidNo,
        JCIC: member.jcicNo,
        NIC: member.cnicNo,
        Gender: member.gender,
        Age: member.age,
        Remarks: member.remarks,
      });
      if (response?.status === "Success") {
        setMember(memberObj);
        launchToast("success", response.message);
      }
      setLoading(false);
    }
  };

  if (loading) {
    return <MUIProgress></MUIProgress>;
  }

  return (
    <main className="main">
      <section className="section !w-full sm:!w-[600px]">
        <h2 className="heading !text-left">Member Entry</h2>
        <form
          className="grid grid-cols-1 sm:grid-cols-2 gap-x-5 mt-8"
          onSubmit={addMember}
        >
          <MUITextField
            names={{ label: "Name", key: "name" }}
            state={member}
            setState={setMember}
          />
          <MUITextField
            names={{
              label: "Father/Husband Name",
              key: "fatherAndHusbandName",
            }}
            state={member}
            setState={setMember}
          />
          <MUITextField
            names={{ label: "Surname", key: "surname" }}
            state={member}
            setState={setMember}
            required={false}
          />
          <MaskedInput
            type="NIC"
            names={{ label: "CNIC #", key: "cnicNo" }}
            state={member}
            setState={setMember}
            error={error.cnicNo}
          />
          <MUISelect
            names={{ label: "JCIC / JID #", key: "membershipNo" }}
            state={member}
            setState={setMember}
          >
            <MenuItem value="jcicNo">JCIC #</MenuItem>
            <MenuItem value="jidNo">JID #</MenuItem>
            <MenuItem value="both">Both</MenuItem>
          </MUISelect>
          {(member.membershipNo === "both" ||
            member.membershipNo === "jcicNo") && (
            <MaskedInput
              type="JCIC"
              names={{ label: "JCIC #", key: "jcicNo" }}
              state={member}
              setState={setMember}
              error={error.jcicNo}
            />
          )}
          {(member.membershipNo === "both" ||
            member.membershipNo === "jidNo") && (
            <MaskedInput
              type="JID"
              names={{ label: "JID #", key: "jidNo" }}
              state={member}
              setState={setMember}
              error={error.jidNo}
            />
          )}
          <MUISelect
            names={{ label: "Gender", key: "gender" }}
            state={member}
            setState={setMember}
          >
            <MenuItem value="M">Male</MenuItem>
            <MenuItem value="F">Female</MenuItem>
          </MUISelect>
          {/* <MUIDatePicker
            names={{ label: "Date of Birth", key: "dob" }}
            state={member}
            setState={setMember}
          /> */}
          <MUITextField
            type="number"
            names={{ label: "Age", key: "age" }}
            state={member}
            setState={setMember}
          />
          <MUITextField
            names={{ label: "Remarks", key: "remarks" }}
            state={member}
            setState={setMember}
            required={false}
          />
          <div className="w-full mt-5 col-span-1 sm:col-span-2 flex justify-end">
            <button className="btn btn-blue max-sm:w-full">
              <AddIcon />
              Add Member
            </button>
          </div>
        </form>
      </section>
    </main>
  );
};

export default MemberEntry;
