import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const MUIConfirmDialog = ({ dialogDetails, setDialogDetails }) => {
  const { open, message, buttonName, function: dialogFunc } = dialogDetails;

  const handleClose = () => {
    setDialogDetails({
      open: false,
      message: "",
      buttonName: "",
      function: () => {},
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="bg-red text-white !mb-8">
        Confirm
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          className="!text-lg !text-stone-800"
        >
          This action will <span className="font-semibold">{message}.</span> Are
          you sure you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button className="btn my-3 mr-2" onClick={handleClose}>
          <CloseIcon className="!text-xl mb-0.5 -mr-1" />
          Cancel
        </button>
        <button className="btn btn-red mx-3" onClick={dialogFunc}>
          {buttonName}
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default MUIConfirmDialog;
