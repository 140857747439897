import React, { useState } from "react";
import { MUIProgress, MUITextField } from "../../components";
import LockResetIcon from "@mui/icons-material/LockReset";
import { post, launchToast } from "../../functions";

const credentialsObj = {
  currentPass: "",
  newPass: "",
  confirmPass: "",
};

const ChangePassword = () => {
  const [credentials, setCredentials] = useState(credentialsObj);
  const [loading, setLoading] = useState(false);

  const changePassword = async (e) => {
    e.preventDefault();
    const { currentPass, newPass, confirmPass } = credentials;
    if (newPass !== confirmPass) {
      return launchToast("warn", "Passwords don't match");
    }

    setLoading(true);
    const response = await post("/auth/changepassword", {
      current_password: currentPass,
      password: newPass,
      password_confirmation: confirmPass,
    });
    if (response?.status === "Success") {
      setCredentials(credentialsObj);
      launchToast("success", response.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <MUIProgress></MUIProgress>;
  }

  return (
    <main className="main">
      <section className="section text-center">
        <h2 className="heading">Change Password</h2>
        <form onSubmit={changePassword} className="mt-6">
          <MUITextField
            type="password"
            names={{ label: "Current Password", key: "currentPass" }}
            state={credentials}
            setState={setCredentials}
            minLength={6}
          />
          <MUITextField
            type="password"
            names={{ label: "New Password", key: "newPass" }}
            state={credentials}
            setState={setCredentials}
            minLength={6}
          />
          <MUITextField
            type="password"
            names={{ label: "Confirm Password", key: "confirmPass" }}
            state={credentials}
            setState={setCredentials}
            minLength={6}
          />
          <button className="btn btn-blue w-full mt-3">
            <LockResetIcon className="!text-xl" />
            Change
          </button>
        </form>
      </section>
    </main>
  );
};

export default ChangePassword;
