import React from "react";
import { TableCell, TableRow } from "@mui/material";
import MUIExpandedCell from "./MUIExpandedCell";
import { actionBtns, checkExpand } from "../../../functions";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const MUITableRow = ({ rowData, index, openEditStationDialog }) => {
  const [expand, setExpand] = React.useState(null);

  return (
    <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      className="odd:bg-[#e0e0e0] hover:bg-[#cccccc] cursor-pointer w-full *:!text-base"
      onClick={() => setExpand(expand === index ? null : index)}
    >
      {expand !== index && (
        <TableCell>
          <p className="mr-6 whitespace-nowrap">
            {checkExpand(expand, index)}
            {rowData.code}
          </p>
        </TableCell>
      )}
      {expand !== index && (
        <TableCell>
          <p className="whitespace-nowrap">{rowData.name}</p>
        </TableCell>
      )}
      {expand !== index && (
        <TableCell align="center">
          <p className="whitespace-nowrap">
            {rowData.locked === 1 ? (
              <LockIcon className="!text-blue" />
            ) : (
              <LockOpenIcon className="!text-blue" />
            )}
          </p>
        </TableCell>
      )}
      {/* {expand !== index && (
        <TableCell>
          <p className="whitespace-nowrap">{rowData.address}</p>
        </TableCell>
      )} */}
      {expand !== index && (
        <TableCell>{actionBtns(() => openEditStationDialog(index))}</TableCell>
      )}
      {expand === index && (
        <MUIExpandedCell
          rowData={rowData}
          index={index}
          expand={expand}
          openEditStationDialog={openEditStationDialog}
        />
      )}
    </TableRow>
  );
};

export default MUITableRow;
