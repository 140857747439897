import React from "react";
import AppRoutes from "./routes/AppRoutes";
import { Toast } from "./components";
import "./styles/App.css";

const App = () => {
  return (
    <React.Fragment>
      <AppRoutes />
      <Toast />
    </React.Fragment>
  );
};

export default App;
